(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-bons-plans');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            pagination: true,
            /*perPage: 4,
            perMove: 1,
            focus: undefined,*/

            breakpoints: {
                600: {
                    arrows: false,
                },
            }
        }).mount();
    });

})();
