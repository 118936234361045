// Some easing functions
// See https://gist.github.com/gre/1650294 and https://easings.net
// only considering the t value for the range [0, 1] => [0, 1]

// others easings (back / bounce) : https://github.com/AndrewRayCode/easing-utils/blob/master/src/easing.js

const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
const easeInOutQuart = (t) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t);
const easeInOutQuint = (t) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t);
const easeInOutSine = (t) => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2;
const easeInOutElastic = (t) => ((t -= 0.5) < 0 ? (0.02 + 0.01 / t) * Math.sin(50 * t) : (0.02 - 0.01 / t) * Math.sin(50 * t) + 1);


// Scroll smooth de la page jusqu'à la position Y d'un élément
const scrollToTarget = function (target, offset = 0, duration = 'auto', easing = easeInOutCubic, callback) {
    const easingFunc = (easing) ? easing : easeInOutCubic;
    const scrollStart = window.scrollY;
    const scrollDelta = target.getBoundingClientRect().top + offset;
    const startTime = performance.now();

    // Calcul auto de la durée du scroll en fonction de la distance parcourue (borné à [800ms - 1600ms])
    if (duration === 'auto') {
        duration = Math.floor(Math.abs(scrollDelta) / 1.2);
        duration = Math.min(Math.max(duration, 800), 1600);
    }

    // Boucle d'animation du scroll en easing
    const doScroll = () => {
        const time = performance.now() - startTime;            // temps écoulé
        const percent = Math.min(time / duration, 1);       // pourcentage de temps écoulé entre [0-1]
        window.scrollTo(0, scrollStart + scrollDelta * easingFunc(percent));

        if (time < duration) {
            this.rafScrollId = requestAnimationFrame(doScroll);
        } else {
            if (callback) callback();    // fin de l'animation
        }
    };

    // Arrête la boucle d'animation du scroll si rAF est déja en cours
    if (this.rafScrollId) cancelAnimationFrame(this.rafScrollId);
    this.rafScrollId = requestAnimationFrame(doScroll);
};

