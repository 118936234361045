// Scripts pour ajouter ou supprimer la classe "no-scroll"
function addNoScroll() {
    if (!document.body.classList.contains("no-scroll")) {
        document.body.classList.add("no-scroll")
    }
}

function removeNoScroll() {
    if (document.body.classList.contains("no-scroll") && !$(th_overlay.selector_overlay_fiche_sit).hasClass('open')) {
        document.body.classList.remove("no-scroll");
    }
}

th_overlay.addCallbackOpen(addNoScroll);
th_overlay.addCallbackClose(removeNoScroll);




// Déplace le dom du widget Elloah dans l'overlay de reservation sur mobile / tablet
function onOverlayReservationOpen(overlay_id) {
    if (overlay_id === 'overlay-reservation') {
        const overlay = document.getElementById(overlay_id);
        const container = overlay.querySelector('.overlay__body');
        const panelReservation = document.querySelector('.js-panel-reservation-inner');

        if (container && panelReservation) {
            container.appendChild(panelReservation);
        }
    }
}
// Replace le widget dans le panneau de droite
function onOverlayReservationClose(overlay_id) {
    if (overlay_id === 'overlay-reservation') {
        const container = document.querySelector('.js-panel-reservation');
        const panelReservation = document.querySelector('.js-panel-reservation-inner');

        if (container && panelReservation) {
            container.appendChild(panelReservation);
        }
    }
}

th_overlay.addCallbackOpen(onOverlayReservationOpen);
th_overlay.addCallbackClose(onOverlayReservationClose);