(function () {
    // Select sur la version mobile
    const selectMobile = document.querySelector('.js-lang-dropdown-mobile');
    if (selectMobile) {
        selectMobile.addEventListener('change', (e) => {
            window.location.href = selectMobile.options[selectMobile.selectedIndex].value;
        });
    }
})();





