(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-selection-offres');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            pagination: true,
            direction: 'ttb',
            height: '100% + 40px',
            autoHeight: true,
            perPage: 4,
            perMove: 1,
            focus: undefined,
            padding: { top: 40, bottom: 80 },

            breakpoints: {
                980: {
                    destroy: true,
                },
            }
        }).mount();
    });

})();
