// JS qui concerne les single SIT
(function () {

    if (document.body.classList.contains('single-sit')) {

        // Init des sliders de la page
        // ==========================================================================

        // Init du slider des horaires
        const slidersHoraires = document.querySelectorAll('.js-slider-sit-horaires');
        slidersHoraires.forEach(slider => {
            const splide = new Splide(slider).mount();
        });

        // Init du slider du bloc relation
        const slidersRelation = document.querySelectorAll('.js-slider-sit-relation');
        slidersRelation.forEach(slider => {
            const splide = new Splide(slider, {
                breakpoints: {
                    600: {
                        arrows: false,
                    },
                }
            }).mount();
        });


        // Boutons "Voir plus", quand texte tronqué sur les blocs "À propos"
        // ==========================================================================
        const blocViewMore = document.querySelectorAll('.js-view-more');
        blocViewMore.forEach(item => {
            const content = item.querySelector('[data-view-more-content]');
            const trigger = item.querySelector('[data-view-more-trigger]');

            // Comportement de viewmore sur le bloc si sa hauteur est suffisante
            if (content && content.getBoundingClientRect().height >= 220) {
                item.classList.add('view-more-active');

                trigger.addEventListener('click', function () {
                    item.classList.toggle('is-expanded');
                });
            }
        });


        // Bouton dans la barre sticky pour scroller jusqu'au bloc "Carte"
        // ==========================================================================
        const btnMapAnchor = document.querySelectorAll('.js-map-anchor');
        btnMapAnchor.forEach(btn => {
            btn.addEventListener('click', function (e) {
                e.preventDefault();
                const hash = this.hash;
                const target = document.querySelector(hash);
                const header = document.querySelector('.js-barre-navigation');

                // Y offset pour le sticky header
                const offset = (header) ? header.offsetHeight : 0;
                const targetTop = window.scrollY + target.getBoundingClientRect().top - offset - 20;

                $('html, body').animate({
                    scrollTop: targetTop
                }, 800, function () {
                    window.location.hash = hash;
                });
            });
        });
    }
})();

// ------ MAIL chargé en ajax au clic sur 'nous contacter' -----------
function initSITMailAjax() {
    $('[data-js="mailsit"]').on('click', function (eventObject) {

        if (!$(this).hasClass('loaded')) {
            eventObject.preventDefault();

            var _this = $(this);
            const ajaxurl = $(this).attr('data-ajaxurl');
            $.ajax({
                url: ajaxurl,
                method: 'POST',
                data: {
                    action: "single_sit_mailto",
                    postid: $(this).attr('data-postid'),
                },
                success: function (data) {
                    result = JSON.parse(data);
                    if (result.success) {
                        console.log('him' );
                        _this.attr('href', 'mailto:' + result.data[0]);
                        _this.attr('target', '_blank');
                        _this.addClass('loaded');
                         _this[0].click();
                    }
                },
            });
        }

    })
}

initSITMailAjax();


