(function () {
    // Languages selector
    const langDropdownDesktop = document.querySelector('.js-lang-dropdown-desktop');
    if (langDropdownDesktop) {
        const trigger = langDropdownDesktop.querySelector('.lang-dropdown-desktop__btn');
        trigger.addEventListener('click', e => {
            e.preventDefault();
            langDropdownDesktop.classList.toggle('is-open');
        });
    }
})();





