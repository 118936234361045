//spécificités listing Agenda
parseCardAgendaClickOverlay();

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.zoomControl.setPosition('topright');
}


// PAGE LISTING SIT
function callbackMapPageListingLEI(macarte) {
    macarte.zoomControl.setPosition('topright');
}


// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors: {
        mapoverlay: '.js-listing-overlay-map',
        btn_loc: '.js-listing-btn-geoloc',
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: false,
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function () {
    }
});

// listing SIT classique - uniquement map en aside
var listingSitDefaultWithMap = new thListingManager({
    selectors: {
        listing: ".js-listing-default-map",
        mapoverlay: '.js-listing-overlay-map',
        map: '.js-listing-map',
        btn_loc: '.js-listing-btn-geoloc',
    },
    mapOptions: {
        onlyDesktop: true,
        useCluster: true,
    },
    mapOverlayOptions: {
        onlyMobile: false,
        useCluster: true,
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function () {
    }
});

// listing SIT FMA - uniquement une map en overlay + overlay sur les cards
var listingSitFMA = new thListingManager({
    selectors: {
        listing: '.js-listing-fma',
        mapoverlay: '.js-listing-overlay-map',
        btn_loc: '.js-listing-btn-geoloc',
    },
    afterUpdate: function () {
        parseCardAgendaClickOverlay();
    }
});

// listing CPT - pas de map
var listingCPT = new thListingManager({
    selectors: {
        listing: '.js-listing-cpt',
    },
    afterUpdate: function () {
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if (listingSitDefaultWithMap.loaded) {
                listingSitDefaultWithMap.initMapOverlay();
            }
            if (listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
            if (listingSitFMA.loaded) {
                listingSitFMA.initMapOverlay();
            }
        }, 850);
    }
});

